<template>
    <div>
        <div class="bg-card-bg p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Configuration -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Configuration</h1>
                    <div class="bg-card-bg p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput
                                :disabled="!disableAllField"
                                class="w-full"
                                label="Retention Period (should be in Days)"
                                placeholder="Retention Period (should be in Days)"
                                type="number"
                                validation="required|number|min:1|max:30000|number|matches:/^[+]?\d+$/"
                                :validation-messages="{
                                    matches: 'Retention period should be a whole number.',
                                }"
                                v-model="configuration.retention_period"
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                @keypress="isIntegerNumber($event)"
                            />
                            <FormulateInput :disabled="!disableAllField" class="w-full" label="Max Users" placeholder="Max Users" validation="required|number|min:1,number" type="number" v-model="configuration.max_users" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber($event)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput :disabled="!disableAllField" class="w-full" label="Max Subjects" placeholder="Max Subjects" validation="required|number|min:1,number" type="number" v-model="configuration.max_candidate" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber($event)" />
                            <FormulateInput :disabled="!disableAllField" class="w-full" @input="updatePrefix" label="Prefix" :readOnly="isClientOnBoarded" placeholder="Prefix" validation="required|min:3,length" help="Allowed letters, numbers and hyphen (trailing hypen not allowed)"  type="text" v-model="configuration.prefix" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>

                        <div class="grid grid-cols-2 gap-6">
                            <div>
                                    <h2 class="mb-1 mt-1">Dashboards *</h2>
    
                                    <Dropdown
                                        placeholder="Dashboards"
                                        v-model="configuration.dashboards"
                                        :options="dashboards"
                                        multiple
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        @input="(value) => setTenantDashboards(value)"
                                        :config="{ label: 'name', trackBy: 'name' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        height="20px"
                                    />
                                </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="flex my-4  w-full justify-between" v-if="disableAllField">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="isFormSubmit" :text="`${isClientOnBoarded ? 'Save & Next' : 'Save and Onboard Client'}`" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { fetchClientInfo, getClientDashboards, getTenantDashboards } from "../services";
import Loader from "@shared/loader";
const prefixRegex = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/
import { checkPermission } from "@shared/utils/functions"
import { isIntegerNumber } from "@/plugins/functions";
import Dropdown from "@shared/components/dropdown-base";

export default {
    name: "client-configuration",
    components: {
        Button,
        Loader,
        Dropdown
    },
    props: {
        clientDetails:{
            type: Object,
            default: () => {}
        },
        loadingStates:{
            type:Object,
            default: () => {}
        },
    },
    data() {
        return {
            configuration: {
                retention_period: 0,
                max_users: 0,
                max_candidate: 1,
                prefix: null,
                dashboards: []
            },
            loadingState: {
                client: false,
            },
            clientId: null,
            dashboards: [],
        };
    },
    async mounted() {
        await this.getClientDetails();
    },
    computed:{
        isClientOnBoarded(){
            return this.clientDetails.onboarded || false
        },
        isFormSubmit(){
            return this.loadingStates.formSubmit
        },
        // disableAllField(){
        //     return !this.checkPermission('client.edit') || false
        // }
        disableAllField(){
            // if(this.$route.name === 'create-client' && !this.isClientOnboarded)  return this.checkPermission('client.create')
            if(this.$route.name === 'client-details' && !this.isClientOnBoarded) return this.checkPermission('client.create') 
            else if(this.$route.name === 'client-details' && this.isClientOnBoarded) return this.checkPermission('client.edit')
            else return false
        }
    },
    methods: {
        isIntegerNumber,
        checkPermission,
        async getClientDetails() {
            this.loadingState.client = true;
            this.clientId = this.$route.params.clientId;
            await this.getAvailableDashboards()
            if (this.clientId) {
                let response = await fetchClientInfo(this.clientId);
                let { retention_period, max_users, max_candidate, prefix, onboarded } = response;
                this.isClientOnboarded = onboarded
                this.configuration = { retention_period, max_users, max_candidate, prefix, dashboards:[] };
                await this.getClientDashboard()
            }
            this.loadingState.client = false;
        },
        async getAvailableDashboards() {
            const response =  await getTenantDashboards()
            if (response?.data?.dashboards) {
            this.dashboards = response.data.dashboards
            }
        },

        async getClientDashboard() {
            const response = await getClientDashboards(this.clientId)
            if (response?.data?.dashboards) {
                if (this.dashboards) {
                    response.data.dashboards.forEach((res)=>{
                        const availableDashboard = this.dashboards.find((r)=>r.id==res.id)
                        if (availableDashboard) {
                            this.configuration["dashboards"].push(res)
                        }
                    })
                    
                }
            }
        },

        handleSubmit(hasErrors) {
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            }else if(!prefixRegex.test(this.configuration.prefix)){
                this.$toast.error(`Prefix is not an allowed value`);
            }
            else if (this.configuration?.dashboards.length==0) {
                this.$toast.error("Please select a dashboard.")
            }
            else {
                let payload = {
                    data: this.configuration,
                    onBoard: !this.isClientOnboarded
                }
                this.$emit("submitForm", payload);
            }
        },
        updatePrefix(eve) {
            this.configuration.prefix = eve?.toLowerCase();
        },
    },
};
</script>

<style lang="scss" scoped></style>
