<template>
    <div class="bg-card-bg p-6 rounded-lg">
        <div class="py-24 flex items-center justify-center" v-if="isLoading">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="grid grid-cols-2 gap-x-6">
                <FormulateInput :disabled="!showSaveBtn || isClientOnboarded" class="w-full" label="Client name" placeholder="Client name" type="text" validation="required|matches:/^[a-zA-Z0-9\s]+$/" :validation-messages="{ matches : 'Client name cannot contain any special characters'}" v-model="client_information.name" :element-class="(context, classes) => isClientOnboarded ? ['flex-1 min-w-full disableField'].concat(classes) : ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput :disabled="!showSaveBtn" v-if="client_information.onboarding_date" class="w-full" label="Onboarding date" placeholder="Onboarding date" readonly type="date" v-model="client_information.onboarding_date" :element-class="(context, classes) => client_information.onboarding_date ? ['flex-1 min-w-full disableField'].concat(classes) : ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput :disabled="!showSaveBtn" v-if="client_information.account_number" class="w-full" label="Account Number" placeholder="Account Number" readonly type="number" v-model="client_information.account_number" :element-class="(context, classes) => client_information.account_number ? ['flex-1 min-w-full disableField'].concat(classes) : ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput :disabled="!showSaveBtn"  class="w-full" label="Website" placeholder="Website" type="url" v-model="client_information.website" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
            </div>
            <!-- <div class="grid grid-cols-2 gap-6"></div> -->
            <div class="grid grid-cols-2 gap-6">
                <FormulateInput :disabled="!showSaveBtn" class="w-full" label="Company registration number" placeholder="Company registration number" type="number" v-model="client_information.registration_no" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isNumber($event)" />
                <FormulateInput :disabled="!showSaveBtn" class="w-full" label="Final report alert emails" :placeholder="client_information.final_report_alert_emails.length ? '' : 'Final report alert emails'" type="pills" v-model="client_information.final_report_alert_emails" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
            </div>
            <div class="grid grid-cols-2 gap-6">
                <FormulateInput :disabled="!showSaveBtn" class="w-full" label="Client action alert emails" :placeholder="client_information.client_action_alert_emails.length ? '' : 'Client action alert emails'" type="pills" v-model="client_information.client_action_alert_emails" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput :disabled="!showSaveBtn" class="w-full" label="Telephone" placeholder="Telephone" type="tel" v-model="client_information.telephone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber($event)" />
            </div>
            <div class="grid grid-cols-2 gap-6">
                <FormulateInput :disabled="!showSaveBtn" type="image" name="client logo" :uploader="uploadClientLogo" @file-removed="removeClientLogo()" :value="client_information.logo_url" label="Client Logo" help="Select a png, jpg, jpeg or svg to upload." />
            </div>
            <div class="flex my-4 justify-end w-full" v-if="showSaveBtn" >
                <Button  text="Save & Next" :loader="isFormSubmit" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import axios from "@/axios";
import { fetchClientInfo } from "../services";
import { checkPermission } from "@shared/utils/functions"
import {isNumber, isIntegerNumber} from "@/plugins/functions";

export default {
    name: "client_information",
    components: {
        Button,
        Loader,
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        },
        isClientOnboarded:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            client_information: {
                name: null,
                // onboarding_date: new Date().toISOString().substring(0, 10),
                // account_number: Math.floor(100000 + Math.random() * 900000),
                website: null,
                registration_no: null,
                final_report_alert_emails: [],
                client_action_alert_emails: [],
                telephone: null,
                logo_url: null,
            },
            clientId: null,
            isLoading: false,
        };
    },
    computed: {
        isFormSubmit(){
            return this.loadingStates.formSubmit
        },
        showSaveBtn(){
            if(this.$route.name === 'create-client' && !this.isClientOnboarded)  return this.checkPermission('client.create')
            else if(this.$route.name === 'client-details' && !this.isClientOnboarded) return this.checkPermission('client.create') 
            else if(this.$route.name === 'client-details' && this.isClientOnboarded) return this.checkPermission('client.edit')
            else return false
        },
    },
    async mounted() {
        await this.getClientDetails();
    },
    methods: {
        isNumber,
        isIntegerNumber,
        checkPermission,
        async getClientDetails() {
            this.isLoading = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                let response = await fetchClientInfo(this.clientId);
                let { name, onboarding_date, account_number, website, registration_no, final_report_alert_emails, client_action_alert_emails, telephone, logo_url } = response;
                this.client_information = {
                    name,
                    onboarding_date: new Date(onboarding_date).toISOString().substring(0, 10),
                    account_number,
                    website,
                    registration_no,
                    final_report_alert_emails: final_report_alert_emails || [],
                    client_action_alert_emails: client_action_alert_emails || [],
                    telephone,
                    logo_url: logo_url ? [{ url: logo_url }] : null,
                };
            }
            this.isLoading = false;
        },
        handleSubmit(hasErrors) {
            let client_data = { ...this.client_information, name:this.client_information.name.trim(), logo_url: this.client_information.logo_url ? encodeURI(this.client_information.logo_url[0].url) : null };
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            }else if(!client_data.name){
                this.$toast.error(`Please fill all the required fields`);
            }
            else {
                
                if (this.client_information && this.client_information.onboarding_date) {
                    client_data = { ...client_data, onboarding_date: new Date(this.client_information?.onboarding_date).toISOString() };
                }
                let payload = {
                    data: client_data,
                    onBoard: false,
                };
                this.$emit("submitForm", payload);
            }
        },

        async uploadClientLogo(file, progress) {
            // console.log(file, "file")
            if (file) {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                let allowedFiles = ["png", "svg", "jpeg", "jpg"];
                if (!allowedFiles.includes(fileExtension)) return this.$toast.error("Only accept svg, png, jpg, jpeg");
            }
            if (file.size / 1e6 > 50) {
                this.client.logo_url = [];
                return this.$toast.error("File size should be less than 50 MB");
            }
            progress(0);
            let url = `/client/logo`;
            let formData = new FormData();
            formData.append("file", file);
            try {
                let { data } = await axios.post(url, formData);
                this.client_information = { ...this.client_information, logo_url: [{ url: data?.data?.logo_url }] };
                progress(100);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to upload logo");
                console.log(error, "error");
            }
            return Promise.resolve({});
        },

        async removeClientLogo() {
            if (!this.clientId) {
                let url = `/client/logo`;
                let payload = {
                    logo_url: this.client_information?.logo_url[0]?.url,
                };
                await axios.delete(url, { data: payload });
            }
            this.client_information = { ...this.client_information, logo_url: null };
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .formulate-files .formulate-file-image-preview:hover{
        transition-delay: .2s !important;
    transform: scale(2) !important;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(8, 8, 8, 0.1);
    }

    .disableField{
        background-color: #e7e7e7 !important;
        opacity: 0.8;
    }
}
</style>
