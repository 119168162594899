<template>
    <div class="flex flex-1 overflow-y-auto flex-col space-y-6 max-h-full max-w-full overflow-x-hidden">
        <div class="grid flex-1 grid-cols-2 space-x-6 overflow-y-auto max-w-full">
            <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingStates && loadingStates.teams" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <ListView :disableCheckbox="assigningWorkAllocation.team || !disableGroups" title="Groups Assigned to Client" @handleSelect="selectAll($event, 'teams')"  @rowClick="selectTeam($event)" v-else :listItems="filterdTeams" :showSearch="true" class="h-full overflow-y-auto" @handleFilterResult="handleFilterResult($event, 'teams')" />
            </div>
            <div class="overflow-y-auto bg-card-bg p-4 rounded-md max-w-full" style="min-height: 300px">
                <div v-if="loadingStates && loadingStates.users" class="flex justify-center items-center min-h-full">
                    <Loader />
                </div>
                <!-- @handleSelect="selectAll($event, 'users')" use this in case of select all func. -->
                <ListView :disableCheckbox="assigningWorkAllocation.user || !disableUsers" title="Users Assigned to Client"   v-else :listItems="filterdUsers" @rowClick="selectUser($event)" :showSearch="true" class="h-full overflow-y-auto" valueKey="user_name" @handleFilterResult="handleFilterResult($event, 'users')" />
            </div>
        </div>
        <!-- <div class="flex justify-between">
            <Button text="cancel" type="outline" />
            <Button text="Save" type="primary" />
        </div> -->
    </div>
</template>

<script>
// import Button from "@shared/components/button";
import ListView from "@shared/components/lists";
import axios from "@/axios";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions"

export default {
    name: "client-work-allocation",
    components: {
        // Button,
        ListView,
        Loader,
    },
    props: {},
    data() {
        return {
            clientId: null,
            loadingStates: {
                teams: false,
                users: false,
            },
            serchValues:{
                teams:'',
                users: ''
            },
            workAllocationData:{
                users: [],
                teams: []
            },
            assigningWorkAllocation:{
                team: false,
                user: false
            }
        };
    },
    computed:{
        filterdTeams(){
            return this.workAllocationData?.teams?.filter(el => (el.name?.toLowerCase()?.includes(this.serchValues.teams.toLowerCase())))
        },
        filterdUsers(){
            return this.workAllocationData?.users?.filter(el => (el.user_name?.toLowerCase()?.includes(this.serchValues.users.toLowerCase())))
        },
        disableUsers(){
            return this.checkPermission('client.edit') && this.checkPermission('users.read')
            // TODO:uncomment when users permissions added, nad remove current return
            // return this.checkPermission('client.edit')
        },
        disableGroups(){
            return this.checkPermission('client.edit') && this.checkPermission('groups.read')
            // TODO:uncomment when  groups permissions added, nad remove current return
            // return this.checkPermission('client.edit')
        },
    },
    async mounted() {
        this.loadingStates.teams = true;
        this.loadingStates.users = true;
        this.clientId = this.$route.params.clientId
        this.workAllocationData = await this.fetchWorkAllocationList();
    },
    methods: {
        checkPermission,
        selectAll(event, type){
            this.workAllocationData[type] = this.workAllocationData[type].map(el => ({...el, checked:event || false}))
        },
        handleFilterResult(event, type){
            this.serchValues[type] = event
        },
        async fetchWorkAllocationList() {
            let user_data = await this.fetchUsersList()
            let team_data =  await this.fetchTeamsList()
            let workAllocationData = {
                users: user_data.map(el => ({...el, checked:el.allotted})),
                teams: team_data.map(el => ({...el, checked:el.allotted})),
            };
            return workAllocationData;
        },
        async fetchUsersList() {
            this.loadingStates.users = true;
            let url = `work-allocation/users/tenant?client_id=${this.clientId}`;
            let users = [];
            try {
                const { data } = await axios.get(url);
                users = data;
            } catch (error) {
                console.log(error, "Error");
            }
            this.loadingStates.users = false;
            return users;
        },
        async fetchTeamsList() {
            this.loadingStates.teams = true;
            let url = `work-allocation/groups/tenant?client_id=${this.clientId}`;
            let teams = [];
            try {
                const { data } = await axios.get(url);
                teams = data;
            } catch (error) {
                console.log(error, "Erro");
            }
            this.loadingStates.teams = false;
            return teams;
        },

        async updateTeamAllocation(team) {
            let url = `work-allocation/client/${this.clientId}/group/${team.id}/tenant`;
            try {
                const { data } = await axios.post(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || "team assigned to client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to assign team to client");
            }
        },

        async removeTeamAllocation(team) {
            let url = `work-allocation/client/${this.clientId}/group/${team.id}/tenant`;
            try {
                const { data } = await axios.delete(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || "team removed from client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to remove team from client");
            }
        },

        async updateUserAllocation(user) {
            let url = `work-allocation/client/${this.clientId}/user/${user.id}/tenant`;
            try {
                const { data } = await axios.post(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || "user assigned to client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to assign user to client");
            }
        },

        async removeUserAllocation(user) {
            let url = `work-allocation/client/${this.clientId}/user/${user.id}/tenant`;
            try {
                const { data } = await axios.delete(url);
                this.prefetchedworkAllocationData = this.workAllocationData;
                this.$toast.success(data.message || "user assigned to client");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to assign user to client");
            }
        },

        async selectTeam({row, value}) {
            let team = {...row, allotted:value}
            this.assigningWorkAllocation.team = true;
            if (team.allotted) {
                await this.updateTeamAllocation(team);
            } else {
                await this.removeTeamAllocation(team);
            }
            this.assigningWorkAllocation.team = false;
        },

        async selectUser({row, value}) {
            let user = {...row, allotted:value}
            this.assigningWorkAllocation.user = true;
            if (user.allotted) {
                await this.updateUserAllocation(user);
            } else {
                await this.removeUserAllocation(user);
            }
            this.assigningWorkAllocation.user = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
