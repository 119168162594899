import { render, staticRenderFns } from "./create-client.html?vue&type=template&id=5b7c43bf&scoped=true&external"
import script from "./create-client.js?vue&type=script&lang=js&external"
export * from "./create-client.js?vue&type=script&lang=js&external"
import style0 from "./create-client.scss?vue&type=style&index=0&id=5b7c43bf&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b7c43bf",
  null
  
)

export default component.exports