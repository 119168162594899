import axios from "@/axios";

const fetchClientInfo = async (clientId) => {
    let url = `/client/${clientId}/new`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const initializeClient = async (payload) => {
    const url = `/client/init`;
    const { data } = await axios.post(url, payload);
    return data.data;
};

const onBoardClient = async (clientId) => {
    let res = {
        data: null,
        error: null,
    };
    const url = `/client/${clientId}/onboard`;
    try {
        const { data } = await axios.post(url);
        res.data = data;
    } catch (error) {
        res.error = error;
    }
    return res;
};

const updateClientInfo = async (clientId, payload) => {
    const url = `/client/${clientId}`;
    const { data } = await axios.patch(url, payload);
    return data;
};

// Contacts
const fetchClientContacts = async (clientId) => {
    let url = `/client/${clientId}/contact`;

    let { data } = await axios.get(url);
    return data;
};

const createAndUpdateClientContacts = async (clientId, payload) => {
    let url = `/client/${clientId}/contact`;

    let { data } = await axios.put(url, payload);
    return data;
};

// ENd Contacts

// Address
const getAddresses = async (clientId) => {
    let url = `/client/${clientId}/address`;

    let { data } = await axios.get(url);
    return data;
};

const createAndUpdateClientAddress = async (clientId, payload) => {
    let url = `/client/${clientId}/address`;

    let { data } = await axios.put(url, payload);
    return data;
};

// End Address

const getCurrencyList = async () => {
    let url = `/data/dropdown/currency`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const fetchTenantCurrencyDetails = async (currencyId) => {
    let url = `/currency/${currencyId}`
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
}

const fetchTenantDetails = async (tenantId) => {
    let url = `/tenant/${tenantId}`
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
}

const getCountryList = async () => {
    let url = `/data/dropdown/country`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const getChecksAssignedtoClient = async (clientId) => {
    const url = `/tenant-check/client-checks/${clientId}`;
    try {
        let { data } = await axios.get(url);
        return data.data;
    } catch (error) {
        console.log(error);
    }
};

const getAllClientPackages = async (clientId) => {
    const url = `/screening-package/client/${clientId}`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("error>>", error);
    }
};

const getTenantDashboards = async () => {
    const url = `/dashboard/assigned-dashboards`
    return await axios.get(url)
}

const getClientDashboards = async (clientId) => {
    const url = `/dashboard/client/assigned-dashboards/${clientId}`
    return await axios.get(url)
}

const assignDashboard = async (payload) => {
    const url = '/client/assign-dashboard'
    await axios.post(url, payload)
}

const validEmail = (value) => {
    // eslint-disable-next-line no-useless-escape
    const nameValue = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return nameValue.test(value);
  };

export { 
    fetchClientInfo, 
    initializeClient, 
    updateClientInfo, 
    fetchClientContacts, 
    createAndUpdateClientContacts, 
    getAddresses, 
    createAndUpdateClientAddress, 
    getCurrencyList, 
    onBoardClient, 
    getChecksAssignedtoClient, 
    getAllClientPackages, 
    getCountryList,
    fetchTenantCurrencyDetails,
    fetchTenantDetails,
    getTenantDashboards,
    getClientDashboards,
    assignDashboard,
    validEmail
};
