<template>
    <div class="flex flex-col max-h-full gap-4 flex-1">
        <div class="flex items-end gap-2" v-if="showSearch">
            <Checkbox @input="$emit('selectAll', $event)" v-if="selectionEnabled" :updateSelectAllVal="updateSelectAllVal" :disabled="!listItems?.length" :isSelectAllInput="isSelectAllInput"/>
            <div class="search_container w-full">
                <Input @input="(value) => $emit('handleFilterResult', value)" placeholder="search" icon="search" />
            </div>
        </div>
        <div
            v-if="filters.length"
            class="filters"
        >
            <FormulateInput
                v-for="filter in filters"
                :key="filter.id"
                :value="filter.value"
                @input="filter.reset"
                class="w-full"
                :options="filter.options"
                variant="alt"
                :disabled="filter.disabled"
                placeholder="Select Entity Type"
                :type="filter.type || 'multi-select'"
                element-class="filters__item"
                v-bind="filter.props"
            />
        </div>

        <div v-if="isAdditionalForm">
            <label>
                <input type="radio" name="collect_data_from" value="New Data Collection" @click="handleCollectData" checked> New Data Collection 
            </label>

            <label>
                <input type="radio" name="collect_data_from" value="Existing Case Data" @click="handleCollectData"> Existing Case Data
            </label>
        </div>

        <div v-if="filteredListItems && filteredListItems.length > 0" class="overflow-y-auto scroll-bar  rounded-lg flex flex-col h-full border-box-stroke" :class="showBorder && 'divide-y divide-var(--theme-color-box-stroke)-200 border border-base-300' + containerClass " >
            <div v-for="item,index in filteredListItems" class="flex cursor-pointer gap-2 items-center p-2 max-w-full " :class="showHover && 'hover:bg-blue-100' " :key="index">
                <slot name="item" :item="item">
                    <span v-if="showIcon">
                        <font-awesome-icon v-if="icon" :icon="icon" class="mr-2 text-sm" :class="iconClass" />
                    </span>
                    {{ item[valueKey] }}
                </slot>
            </div>
        </div>
        <div v-else class="flex py-10 justify-center h-full border rounded" :class="containerClass">
            <Empty :showIcon="true" text="No result found" />
        </div>
    </div>
</template>
<script>
import Input from "../../input";
import Empty from "../../empty";
import Checkbox from "@/components/checkbox";

export default {
    name: "list-view",
    components: {
        Input,
        Empty,
        Checkbox,
    },
    props: {
        listItems: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: "",
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        iconClass: {
            type: String,
            default: "text-blue-500",
        },
        valueKey: {
            type: String,
            default: "",
        },
        containerClass: {
            type: String,
            default: "",
        },
        showSearch:{
            type:Boolean,
            default: false
        },
        showBorder:{
            type:Boolean,
            default: true
        },
        showHover:{
            type:Boolean,
            default: false
        },
        selectionEnabled: {
            type:Boolean,
            default: false
        },
        updateSelectAllVal: {
            type: Boolean,
            default: false,
        },
        isSelectAllInput: {
            type: Boolean,
        },
        isAdditionalForm:Boolean,
        /**
         * Custom filters for viewed list
         * @typedef Filter
         * @type {Object}
         * @property {string} id - The identifier of the filter.
         * @property {Array<Object>} options - The filter options retrieved from `entityTypeOptions`.
         * @property {Array<Object | String>} value - The currently selected filter values
         * @property {Function} check - A function to check if an element matches the filter.
         * @property {Function} reset - A function to reset the filter value.
         * @property {Object} props - The properties for the used component configurations
        */
        /**
         * Filters applied to the viewed list.
         * @type {Array<Filter>}
        */
        filters: {
            type: Array,
            default: () => []
        },
    },

    mounted() {
        this.filters.forEach((filter) => filter.reset?.());
    },

    computed: {
        filteredListItems() {
            return this.listItems
                .filter((el) => {
                    return this.filters.every((filter) => {
                        return filter.check(el)
                    })
                })
        },
    },
    methods: {
        handleCollectData($event) {
            this.$emit("handleCollectData", $event.target.value);
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .filters {
        &__item {
            min-height: 40px !important;
        }
    }
}
</style>
